import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import ReactDataGrid, { Row } from "react-data-grid";

import Button from "@material-ui/core/Button";
import IconRefresh from "@material-ui/icons/Refresh";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";

import { InstructionGridColumns, RecalculateRowValues } from "./instructiongridsetup";

import { SnackContext } from "../lib/context/SnackContext";

import { DraggableRowRenderer } from "../lib/components/DraggableRow";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";
import { getProducerByProducerCode } from "../lib/api/producer";
import { getProducerFinanceEstimatedCostItemGroups } from "../lib/api/estimatedcostitemgroups";

let gridRefInstructionsGrid;

let currentGroupNum = 0;
const RowRendererWrap = ({ onRowReorder, props, classes }) => {
  if (currentGroupNum == 0) {
    currentGroupNum = props.row.groupnum;
  }
  const underscore = props.row.groupnum != currentGroupNum;
  currentGroupNum = props.row.groupnum;
  return <DraggableRowRenderer props={props} classes={classes} onRowReorder={onRowReorder} oddRow={currentGroupNum % 2 != 0} underscore={underscore} />;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    rowNormal: {},
    rowDragging: { borderStyle: "solid", borderWidth: "1px", borderColor: "black" },
    rowOver: {
      backgroundColor: "white",
    },
    rowOdd: {
      backgroundColor: "aliceblue",
    },
    rowUnderScore: {
      borderWidth: "1px 0px 0px 0px",
      borderStyle: "solid",
    },
  });
type InstructionGridProps = {
  onInstructionsDataChange: any;
  onInstructionsDataChangePush: any;
  data: any;
  targets: any;
  counts: any;
  classesArr: any;
  palletspecs: any;
  coldrooms: any;
  brands: any;
  inventories: any;
  mgps: any;
  commission: any;
  exchange: any;
  costestimations: any;
  local: any;
  fixed: any;
  handleCopy: any;
  handleRemove: any;
  handleAdd: any;
  handleSaveLines: any;
  estimatedcost: any;
  variety: any;
  handleResetData: any;
  week: any;
  values: any;
} & WithStyles<typeof styles>;

class InstructionGridUnstyled extends React.Component<InstructionGridProps, any> {
  state = {
    loading: true,
    onInstructionsDataChange: undefined,
    onInstructionsDataChangePush: undefined,
    rows: undefined,
    rowsOrg: undefined,
    targets: undefined,
    targetsRegion: [],
    targetsCountry: [],
    targetsMarket: [],
    counts: [],
    classesArr: [],
    palletspecsOrg: [],
    palletspecsCarton: [],
    palletspecsCode: [],
    coldrooms: [],
    brands: [],
    inventories: [],
    mgps: [],
    commission: 0,
    exchange: {},
    local: undefined,
    fixed: undefined,
    handleCopy: undefined,
    handleRemove: undefined,
    handleAdd: undefined,
    currentColumnSelected: 0,
    currentRowSelected: 0,
    estimatedcost: undefined,
    variety: undefined,
    selectedIndexes: [],
    handleResetData: undefined,
    moveOrder: false,
    selectedRows: new Set<React.Key>(),
    week: undefined,
    producerFinanceEstimatedCostItemGroups: [],
  };

  constructor(props) {
    super(props);

    gridRefInstructionsGrid = React.createRef();

    this.state.onInstructionsDataChange = props.onInstructionsDataChange;
    this.state.onInstructionsDataChangePush = props.onInstructionsDataChangePush;
    this.state.loading = true;
    this.state.rows = props.data;
    this.state.rowsOrg = props.data;
    this.state.counts = props.counts;
    this.state.commission = props.commission;
    this.state.exchange = props.exchange;
    this.state.local = props.local.toString().toLowerCase() == "true" ? true : false;
    this.state.fixed = props.fixed.toString().toLowerCase() == "true" ? true : false;
    this.state.handleCopy = props.handleCopy;
    this.state.handleRemove = props.handleRemove;
    this.state.handleAdd = props.handleAdd;
    this.state.handleResetData = props.handleResetData;
    this.state.estimatedcost = props.estimatedcost;
    this.state.variety = props.variety;
    this.state.week = props.week;
    this.state.mgps = props.mgps.map((item) => ({ id: item.id, value: `${item.code}` }));
    this.state.palletspecsOrg = props.palletspecs;
    this.state.targets = props.targets;
    this.state.classesArr = props.classesArr.map((item) => ({ id: item.id, value: item.code }));
    this.state.targetsRegion = this.state.targetsRegion.sort((a, b) => (a.value < b.value ? -1 : 1));

    this.state.inventories = props.inventories
      .sort((a, b) => {
        if (a.description > b.description) return 1;
        if (a.description < b.description) return -1;
        return 1;
      })
      .map((item) => ({ id: item.id, value: item.description }));

    this.state.brands = props.brands
      .sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      })
      .map((item) => ({ id: item.id, value: item.code }));

    this.state.coldrooms = props.coldrooms.sort((a, b) => a.code.localeCompare(b.code)).map((item) => ({ id: item.id, value: item.name ? item.name : item.code }));

    props.palletspecs
      .sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      })
      .map((item) => {
        this.state.palletspecsCarton.push({ id: item.carton, value: item.carton, code: item.code, data: item });

        if (!this.state.palletspecsCode.find((x) => x.value == item.code)) {
          this.state.palletspecsCode.push({ id: item.code, value: item.code, data: item });
        }
      });

    props.targets.map((target) => {
      const targetrexist = this.state.targetsRegion.filter((region) => region.value == target.region);
      if (targetrexist.length == 0) {
        this.state.targetsRegion.push({ id: this.state.targetsRegion.length, value: target.region });
      }
    });
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  loadData = async () => {
    const producer = await getProducerByProducerCode(this.props.values.farm);
    if (producer.data && producer.data.length > 0) {
      const producer_id = producer.data[0].id;
      const result = await getProducerFinanceEstimatedCostItemGroups(producer_id);
      this.setState({ producerFinanceEstimatedCostItemGroups: result });
    }
  };

  componentDidMount = async () => {
    this.loadData();
    await this.filterTargets(undefined, undefined);
    this.setState({ loading: false, data: [] });
  };

  filterTargets = async (region, market) => {
    this.setState({ loading: true });

    const { targetCountries, targetMarkets } = this.state.targets.reduce(
      ({ targetCountries, targetMarkets }, target) => {
        if ((!region || target.region == region) && !targetMarkets.find((row) => row.value == target.market)) {
          targetMarkets.push({ id: target.id, value: target.market });
        }

        if ((!market || (target.market == market && (region || target.region) == target.region)) && !targetCountries.find((row) => row.value == target.country)) {
          targetCountries.push({ id: target.id, value: target.country });
        }

        return { targetCountries, targetMarkets };
      },
      {
        targetCountries: [],
        targetMarkets: [],
      },
    );

    this.setState({
      targetsCountry: [{ id: 0, value: "CLEAR" }].concat(
        targetCountries.sort((a, b) => {
          if (a.value < b.value) return -1;
          return 1;
        }),
      ),
      targetsMarket: targetMarkets.sort((a, b) => {
        if (a.value < b.value) return -1;
        return 1;
      }),
      loading: false,
    });
  };

  onGridRowsUpdated = (rows, data) => {
    const newRows = rows.map((item, idx) => {
      let updated = item;

      if (!data.indexes.includes(idx)) {
        return updated;
      }

      updated.isDirty = 1;

      if (data.column.key == "region") {
        updated = { ...updated, market: "", country: "" };
      }

      if (data.column.key == "coldroom") {
        const coldroom = this.state.coldrooms.find((coldroom) => coldroom.value === item.coldroom);
        updated = { ...updated, port_id: coldroom.port_id };
      }

      if (updated.count && updated.count.indexOf("/") == 0) {
        updated.count = updated.count.substring(1);
      }

      if (data.column.key === "country" && updated.country !== "CLEAR") {
        const targetIdentified = this.state.targets.find((target) => target.country == updated.country);

        if (targetIdentified) {
          updated = { ...updated, region: targetIdentified.region };

          if (!updated.market) {
            updated = { ...updated, market: targetIdentified.market };
          }
        }
      }
      if (data.column.key === "country" && updated.country === "CLEAR") {
        this.filterTargets(undefined, undefined);
        updated = { ...updated, market: "", country: "", region: "" };
      } else {
        this.filterTargets(updated.region, updated.market);
      }

      if (data.column.key == "mgp") {
        if (updated.mgp == "FIXED") {
          updated.rangemin = "0.00";
          updated.rangemax = "0.00";
        }
      }

      if (data.column.key === "country") {
        const target = this.state.targets.find((tar) => tar.country == updated.country && tar.market == updated.market && tar.region == updated.region);
        if (target) {
          updated.target_id = target.id;
        }
      }

      if (data.column.key === "pack_code") {
        const palletSpec = this.state.palletspecsOrg.find((row) => row.code === updated.pack_code);
        updated = { ...updated, pack_id: palletSpec.id, pack_carton: palletSpec.carton, count: "" };
      }

      if (data.column.key === "pack_carton") {
        const palletSpec = this.state.palletspecsOrg.find((row) => row.code === updated.pack_code && row.carton === updated.pack_carton);
        updated = { ...updated, pack_id: palletSpec.id, count: "" };
      }

      if (data.column.key === "inventory_display") {
        const inventory = this.state.inventories.find((inv) => inv.value == updated.inventory_display);
        if (inventory) {
          updated = { ...updated, inventory: inventory.id };
        }
      }

      if (data.column.key === "volume" && updated.volume == "#") {
        updated = { ...updated, volume: "ONBEPERK" };
      } else if (updated.volume && !isNaN(updated.volume.replace(/\ /g, "").replace(/\PLTS/g, ""))) {
        updated = { ...updated, volume: `${updated.volume.replace(/\ /g, "").replace(/\PLTS/g, "")} PLTS` };
      }

      return updated;
    });

    const recalculatedRows = newRows.map((row, idx) => {
      if (!data.indexes.includes(idx)) {
        return row;
      }
      return RecalculateRowValues(
        row,
        this.state.commission,
        this.state.exchange,
        this.state.targets,
        this.state.palletspecsOrg,
        this.state.local,
        this.state.fixed,
        this.state.estimatedcost,
        this.state.variety,
        this.state.week,
        this.state.producerFinanceEstimatedCostItemGroups,
      );
    });

    this.setState({ rows: recalculatedRows });
    this.state.onInstructionsDataChange(recalculatedRows);
  };

  handleRecalculateOrderLines = () => {
    const rowsArr = [];
    this.state.rows.map((item) => {
      rowsArr.push(
        RecalculateRowValues(
          item,
          this.state.commission,
          this.state.exchange,
          this.state.targets,
          this.state.palletspecsOrg,
          this.state.local,
          this.state.fixed,
          this.state.estimatedcost,
          this.state.variety,
          this.state.week,
          this.state.producerFinanceEstimatedCostItemGroups,
        ),
      );
    });
    this.setState({ rows: rowsArr }, () => {
      this.state.onInstructionsDataChange(this.state.rows);
      this.state.onInstructionsDataChangePush(this.state.rows);
    });
  };

  reorderRows = (rowSource, rowTarget) => {
    const selectedIndexes = Array.from(this.state.selectedIndexes);

    try {
      if (selectedIndexes && selectedIndexes.length > 0) {
        const draggedRows = [];
        selectedIndexes.forEach((item) => {
          draggedRows.push(this.state.rows.find((row) => row.id == item));
        });

        const undraggedRows = [];
        this.state.rows.forEach((row) => {
          if (!selectedIndexes.find((item) => item == row.id)) {
            undraggedRows.push(row);
          }
        });

        const selectedModifier = selectedIndexes && selectedIndexes.length > 0 ? selectedIndexes.length - 1 : 0;
        if (this.state.moveOrder) {
          // reorder order numbers
          if (rowSource >= rowTarget) {
            const grpnum = rowTarget == 0 ? 1 : Number(undraggedRows[rowTarget - 1].groupnum) + 1;
            draggedRows.forEach((row) => {
              row.groupnum = grpnum;
            });
            for (let index = 0; index < undraggedRows.length; index++) {
              if (index >= rowTarget) {
                undraggedRows[index].groupnum += 1;
                undraggedRows[index].isDirty = 1;
              }
            }
          } else {
            const grpnum = Number(undraggedRows[rowTarget - selectedModifier - 1].groupnum);
            draggedRows.map((row, index) => {
              draggedRows[index].groupnum = grpnum;
            });
            for (let index = 0; index < undraggedRows.length; index++) {
              if (index < rowTarget) {
                undraggedRows[index].groupnum -= 1;
                undraggedRows[index].isDirty = 1;
              }
            }
          }
        }

        const newRows = [...undraggedRows];

        if (rowSource < rowTarget) {
          rowTarget -= draggedRows.length - 1;
        }
        newRows.splice(rowTarget, 0, ...draggedRows);
        newRows.forEach((row) => {
          row.isDirty = 1;
        });

        const finalRows = this.state.moveOrder ? this.resequencerows(newRows) : newRows;
        this.setState({ rows: finalRows, selectedIndexes: [], selectedRows: new Set<React.Key>() }, () => {
          this.state.onInstructionsDataChange(this.state.rows);
        });
      }
    } catch (error) {
      this.context.updateSnack({ show: true, color: "red", message: error });
    }
  };

  resequencerows = (rows, force = false) => {
    //arrange orders with no gaps
    if (this.state.moveOrder || force) {
      let item = { currentgroup: -1, group: -1, line: 0 };
      if (rows.length > 0) {
        for (let index = 0; index < rows.length; index++) {
          const element = rows[index];
          element.isDirty = 1;
          if (item.group == -1) {
            item.group = 1;
            item.currentgroup = element.groupnum;
            item.line = 1;
          }
          if (element.groupnum == item.currentgroup) {
            element.groupnum = item.group;
            element.groupline = item.line;
            item.line += 1;
          } else {
            item.group += 1;
            item.currentgroup = element.groupnum;
            element.groupnum = item.group;
            element.groupline = 1;
            item.line = 2;
          }
        }
      }
    }
    return rows;
  };

  handleRemoveStopOver = (id) => {
    this.state.handleRemove(id, this.state.selectedIndexes);
  };

  handleCopyStopOver = (neworder: boolean = false) => {
    this.state.handleCopy(this.state.selectedIndexes, neworder);
  };

  handleResetData = () => {
    this.state.handleResetData();
  };

  handleAutoMoveOrder = () => {
    this.setState({ moveOrder: !this.state.moveOrder });
  };

  handleReSequenceRows = () => {
    const finalRows = this.resequencerows(this.state.rows, true);
    this.setState({ rows: finalRows, selectedIndexes: [] }, () => {
      this.state.onInstructionsDataChange(this.state.rows);
    });
  };

  onRowSelected = (rows: Set<React.Key>) => {
    const keys = new Set<React.Key>();
    rows.forEach((i) => keys.add(i));
    this.setState({ selectedRows: keys, selectedIndexes: Array.from(keys) }, () => {});
  };

  handleAddRow = () => {
    // const lastRow = this.state.rows[this.state.rows.length - 1];
    // const lastRowIndx = this.state.rows.findIndex((row) => row.id == lastRow.id);
    this.state.handleAdd(this.state.selectedIndexes);
  };

  handleGridCellChange = (pos) => {
    if (pos.rowIdx != this.state.currentRowSelected || pos.idx != this.state.currentColumnSelected) {
      this.setState(
        {
          currentColumnSelected: pos.idx,
          currentRowSelected: pos.rowIdx,
        },
        () => {
          if (pos.idx == 0) {
            // row selection box single click solution
            const row = this.state.rows.filter((item) => !isNullOrUndef(item))[pos.rowIdx];
            const keys: Set<React.Key> = this.state.selectedRows;
            if (!this.state.selectedIndexes.find((idx) => idx == row.id)) {
              keys.add(row.id);
              this.setState({ selectedIndexes: [...this.state.selectedIndexes, row.id] }, () => {});
            } else {
              keys.delete(row.id);
              this.setState({ selectedIndexes: [...this.state.selectedIndexes.filter((item) => item != row.id)] }, () => {});
            }
          }
          gridRefInstructionsGrid.selectCell(pos, true);
        },
      );
    }
  };

  handleGridFill = ({ columnKey, sourceRow, targetRows }) => {
    return targetRows.map((row) => ({ ...row, [columnKey]: sourceRow[columnKey], rowUpdated: 1 }));
  };

  render() {
    const maindivheight = document.getElementById("maingridcontent");
    const gridheight = maindivheight ? maindivheight.clientHeight - 70 : 50;

    return (
      <div>
        {this.state.loading && <div>loading</div>}
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Tooltip title={`Remove Selected Lines`}>
            <Button variant="contained" color="primary" style={{ backgroundColor: "red", marginLeft: "10px" }} onClick={() => this.handleRemoveStopOver(1)}>
              remove selected
            </Button>
          </Tooltip>
          <Tooltip title={`Copy selected lines`}>
            <Button variant="contained" color="primary" style={{ backgroundColor: "green", marginLeft: "10px" }} onClick={() => this.handleCopyStopOver(false)}>
              copy lines
            </Button>
          </Tooltip>
          <Tooltip title={`Copy selected lines`}>
            <Button variant="contained" color="primary" style={{ backgroundColor: "green", marginLeft: "10px" }} onClick={() => this.handleCopyStopOver(true)}>
              copy order
            </Button>
          </Tooltip>
          <Tooltip title={`Create a new line below your current position`}>
            <Button variant="contained" color="primary" style={{ marginLeft: "10px", backgroundColor: "green" }} onClick={this.handleAddRow}>
              new order line
            </Button>
          </Tooltip>
          <Tooltip title={`Recalculate Financial Calculation for all lines`}>
            <Button variant="contained" color="primary" style={{ marginLeft: "10px", backgroundColor: "orange" }} onClick={this.handleRecalculateOrderLines}>
              recalculate lines
            </Button>
          </Tooltip>
          <Tooltip title={`Reset data to last SAVE`}>
            <Button variant="contained" color="primary" style={{ marginLeft: "10px", backgroundColor: "green" }} onClick={this.handleResetData}>
              <IconRefresh />
            </Button>
          </Tooltip>
          <Tooltip title={`Resequence order and line numbers top to bottom`}>
            <Button variant="contained" color="primary" style={{ marginLeft: "10px", backgroundColor: "orange" }} onClick={() => this.handleReSequenceRows()}>
              re-sequence lines
            </Button>
          </Tooltip>
          <FormControlLabel
            control={<Checkbox checked={this.state.moveOrder} onChange={this.handleAutoMoveOrder} name="Move Order" color="primary" style={{ marginLeft: "10px" }} />}
            label="Move Order when dragging"
          />
        </div>
        {!this.state.loading && (
          <DndProvider backend={HTML5Backend}>
            <ReactDataGrid
              onFill={this.handleGridFill}
              className="rdg-light"
              ref={(input) => (gridRefInstructionsGrid = input)}
              columns={InstructionGridColumns(
                this.state.fixed,
                this.state.targetsRegion,
                this.state.targetsMarket,
                this.state.targetsCountry,
                this.state.counts,
                this.state.classesArr,
                this.state.palletspecsCarton,
                this.state.palletspecsCode,
                this.state.coldrooms,
                this.state.brands,
                this.state.inventories,
                this.state.mgps,
                this.handleCopyStopOver,
                this.handleRemoveStopOver,
              )}
              rows={this.state.rows.filter((item) => !isNullOrUndef(item))}
              selectedRows={this.state.selectedRows as any}
              onSelectedRowsChange={this.onRowSelected}
              rowRenderer={(p) => <RowRendererWrap props={p} classes={this.props.classes} onRowReorder={this.reorderRows} />}
              rowKeyGetter={(row) => row && row.id}
              onRowsChange={this.onGridRowsUpdated}
              style={{ height: gridheight }}
              rowHeight={30}
              headerRowHeight={35}
              onSelectedCellChange={this.handleGridCellChange}
            />
          </DndProvider>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(InstructionGridUnstyled);
