import React from "react";
import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconEdit from "@material-ui/icons/Edit";
import IconDelete from "@material-ui/icons/Delete";
import IconCircle from "@material-ui/icons/FiberManualRecord";
import IconCheck from "@material-ui/icons/Check";
import IconClose from "@material-ui/icons/Close";
import IconVisibility from "@material-ui/icons/Visibility";

import { CellActionsFormatter } from "../../../lib/helpers/CellActionsFormatter";
import { FormatterNumber, FormatterNumber2Decimals, RightAlignColumnHeader } from "../../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";
import { User } from "../../../lib/api/users";

const cellWidthActions = 150;
const cellWidthTiny = 40;
const cellWidthSmall = 100;
const cellWidthSmallBigger = 120;
const cellWidthMedium = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const StatusFormatter = ({ row, column }) => {
  const data = row[column.key];
  return (
    <div>
      {data == 1 ? (
        <div>
          <IconClose style={{ color: "red" }} />
        </div>
      ) : (
        <div>
          <IconCheck style={{ color: "green" }} />
        </div>
      )}
    </div>
  );
};

const StatusFormatterContainer = ({ row, column }) => {
  const data = row[column.key];
  return (
    <div>
      {data != 1 ? (
        <div>
          <IconClose style={{ color: "red" }} />
        </div>
      ) : (
        <div>
          <IconCheck style={{ color: "green" }} />
        </div>
      )}
    </div>
  );
};

let currentSpace = true;
export const GridColumns = (data, filters, columnArrangement, columnsWidth, handleCopy, handleEdit, handleRemove, handleChangeStatus, user) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthActions,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleCopy, handleEdit, handleRemove, handleChangeStatus, user);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    { key: "status", name: "Status", width: cellWidthTiny, formatter: StatusFormatter },
    {
      key: "season",
      name: "Season" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"season"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "commodity_full",
      name: "Commodity" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodity_full"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "region",
      name: "Region" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"region"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "market",
      name: "Market" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"market"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "country",
      name: "Country" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"country"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "port",
      name: "Port of Loading",
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"port"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    { key: "container", name: "Container", width: cellWidthTiny, formatter: StatusFormatterContainer },
    { key: "conventional", name: "Conventional", width: cellWidthTiny, formatter: StatusFormatterContainer },
    {
      key: "pack",
      name: "Pack" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pack"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "cartons",
      name: "Cartons Per Pallet" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"cartons"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "Item1",
      name: "Cost Item 1",
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Item1"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "Item2",
      name: "Cost Item 2",
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Item2"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "Item3",
      name: "Cost Item 3",
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Item3"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "Item4",
      name: "Cost Item 4",
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Item4"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "Item5",
      name: "Cost Item 5",
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Item5"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "Item6",
      name: "Cost Item 6",
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Item6"} data={data} section={"estimatedcostgridsetup"} />;
      },
    },
    {
      key: "totalproducercost_zar",
      name: "Total Cost ZAR",
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalproducercost_zar"} data={data} section={"estimatedcostgridsetup"} />
        );
      },
    },
    {
      key: "exchange_usd",
      name: "USD",
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchange_usd"} data={data} section={"estimatedcostgridsetup"} />;
      },
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "exchange_cad",
      name: "CAD",
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchange_cad"} data={data} section={"estimatedcostgridsetup"} />;
      },
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "exchange_eur",
      name: "EUR",
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchange_eur"} data={data} section={"estimatedcostgridsetup"} />;
      },
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "exchange_gbp",
      name: "GBP",
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchange_gbp"} data={data} section={"estimatedcostgridsetup"} />;
      },
      headerRenderer: RightAlignColumnHeader,
    },
    {
      key: "exchange_zar",
      name: "ZAR",
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchange_zar"} data={data} section={"estimatedcostgridsetup"} />;
      },
      headerRenderer: RightAlignColumnHeader,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, columnArrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (row, handleCopy, handleEdit, handleRemove, handleChangeStatus, user: Partial<User>) => {
  const actionsArr = [
    {
      icon: (
        <span title="Status">
          <IconCircle style={{ cursor: "pointer", color: row.status == 1 ? "red" : "green" }} />
        </span>
      ),
      callback: (e) => {
        if (user.estimatedcost_edit) {
          handleChangeStatus(row);
        }
      },
    },
  ];

  if (user.estimatedcost_edit) {
    actionsArr.push(
      {
        icon: (
          <span title="Remove">
            <IconDelete style={{ cursor: "pointer", color: "red" }} />
          </span>
        ),
        callback: (e) => {
          handleRemove(row.id);
        },
      },
      {
        icon: (
          <span title="Copy">
            <FileCopyIcon style={{ cursor: "pointer", color: "#4a9de2" }} />
          </span>
        ),
        callback: (e) => {
          handleCopy(row);
        },
      },
      {
        icon: (
          <span title="Edit">
            <IconEdit style={{ cursor: "pointer", color: "orange" }} />
          </span>
        ),
        callback: (e) => {
          handleEdit(row);
        },
      },
    );
  } else {
    actionsArr.push({
      icon: (
        <span title="View">
          <IconVisibility style={{ cursor: "pointer", color: "gray" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(row);
      },
    });
  }

  return actionsArr;
};
