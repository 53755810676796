import React, { FC, useMemo, useEffect, useState, useContext } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";

import { Form, Field } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";

import numeral from "numeral";
import { FormApi } from "final-form";

import { EstimatedCostsData, estimatedcostsFull } from "../../../lib/api/estimatedcost";
import { commoditiesAllSortedMappedforCombo } from "../../../lib/api/commodity";
import { MaterialSelectComponent } from "../../../lib/helpers/materialcomponents";
import Confirmation from "../../../lib/components/confirmation";
import { targetsSorted } from "../../../lib/api/target";
import { getPortsLoadingForCombo, Port } from "../../../lib/api/port";
import { duplicateCheck } from "./estimatedcostssetup";
import { SnackContext } from "../../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";
import { UserContext } from "../../../lib/context/UserContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    field: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      width: "400px",
      maxWidth: "400px",
      textAlign: "left",
      gap: theme.spacing(2),
      "& p": {
        width: "130px",
        textAlign: "right",
      },
      "& .MuiCheckbox-root": {
        padding: 0,
      },
      "& .MuiSelect-select": {
        whiteSpace: "pre-wrap",
      },
      "& .Mui-error": {
        whiteSpace: "nowrap",
        textAlign: "left",
        color: "red",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    tableCell: {
      borderWidth: "1px",
      paddingLeft: "2px",
      paddingRight: "2px",
      borderColor: "black",
      borderStyle: "solid",
      width: "200px",
    },
    tableCellValue: {
      borderWidth: "1px",
      paddingLeft: "2px",
      paddingRight: "2px",
      borderColor: "black",
      borderStyle: "solid",
      width: "100px",
    },
    tableCellExchangeLabel: {
      borderWidth: "0px",
      borderLeftWidth: "1px",
      borderBottomWidth: "1px",
      paddingLeft: "2px",
      paddingRight: "2px",
      borderColor: "black",
      borderStyle: "solid",
      width: "100px",
    },
    tableCellConventional: {
      border: "0px",
      borderRight: "1px",
      borderColor: "black",
      borderStyle: "solid",
      width: "100px",
      paddingLeft: "2px",
      paddingRight: "2px",
    },
    actionFormButton: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    exchange: {
      display: "flex",
      flexDirection: "row",
      gap: theme.spacing(2),

      "& .MuiFormHelperText-root": {
        width: "94px",
        textAlign: "right",
      },
    },
    exchangeWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "300px",
    },
    exchangeField: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      "& .MuiTextField-root": {
        width: "50px",
      },
      "& .MuiFormHelperText-root": {
        width: "50px",
        textAlign: "left",
      },
    },
  });

type EstimatedCostsSetupHeadProps = {
  currentHead: EstimatedCostsData;
  allData: any[];
  isDuplicate: boolean;
  setIsDuplicate(value: boolean): void;
  submitHeadData(values: any): Promise<number>;
  packspec_id: number;
} & WithStyles<typeof styles>;

const EstimatedCostsSetupHeadUnstyled: React.FunctionComponent<EstimatedCostsSetupHeadProps> = ({
  classes,
  currentHead,
  allData,
  isDuplicate,
  packspec_id,
  setIsDuplicate,
  submitHeadData,
}) => {
  const [commodities, setCommodities] = useState([]);
  const [editing, setEditing] = useState(false);
  const [targets, setTargets] = useState([]);
  const [closeDialog, setCloseDialog] = useState(false);
  const [portsLoading, setPortsLoading] = useState<{ value: number; display: string; data: Port }[]>([]);

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(currentHead);

  const { updateSnack } = useContext(SnackContext);
  const { user } = useContext(UserContext);

  const reloadData = async () => {
    setLoading(true);
    try {
      const data = await estimatedcostsFull(currentHead.id);
      setData({ ...data[0], original_id: data[0].id });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error reloading data. Please try again");
      updateSnack({ show: true, color: "red", message: err });
    }
    setLoading(false);
  };

  const loadData = async () => {
    const [resultCommodities, resultTargets, resultPortsLoading] = await Promise.all([commoditiesAllSortedMappedforCombo(), targetsSorted(), getPortsLoadingForCombo()]);
    setTargets(resultTargets.data);
    setCommodities(resultCommodities);
    setPortsLoading(resultPortsLoading);
  };

  const handleSave = async (values: any) => {
    setCloseDialog(false);
    await submitHeadData(values);
    setReload(true);
    switchEditing();
  };

  const switchEditing = () => {
    setEditing(!editing);
    setReload(false);
  };

  const handleBackdropClick = () => {
    setCloseDialog(true);
  };

  useEffect(() => {
    if (reload) {
      reloadData();
    }
  }, [reload]);

  useEffect(() => {
    const doesExists = duplicateCheck(data, allData, packspec_id);
    setIsDuplicate(!!doesExists);
  }, [data, packspec_id]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.root}>
      {data && (
        <div>
          <table style={{ width: "98%" }}>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>Season: </td>
                <td style={{ fontWeight: "bold", width: "59%" }}>{data.season}</td>
              </tr>
              <tr>
                <td className={classes.tableCell}>Commodity: </td>
                <td className={classes.tableCellValue}>{data.commodity}</td>
              </tr>
              <tr>
                <td className={classes.tableCell}>Region: </td>
                <td className={classes.tableCellValue}>{data.region}</td>
              </tr>
              <tr>
                <td className={classes.tableCell}>Market: </td>
                <td className={classes.tableCellValue}>{data.market}</td>
              </tr>
              <tr>
                <td className={classes.tableCell}>Country: </td>
                <td className={classes.tableCellValue}>{data.country}</td>
              </tr>
              <tr>
                <td className={classes.tableCell}>Port of Loading: </td>
                <td className={classes.tableCellValue}>{data.port}</td>
              </tr>
              <tr>
                <td className={classes.tableCell}>Container: </td>
                <td className={classes.tableCellValue}>{data.container == 1 ? "TRUE" : "FALSE"}</td>
              </tr>
              <tr>
                <td className={classes.tableCell}>Conventional: </td>
                <td className={classes.tableCellConventional}>{data.conventional == 1 ? "TRUE" : "FALSE"}</td>
              </tr>
              <tr>
                <td className={classes.tableCellExchangeLabel}>Exchange: </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <th className={classes.tableCell}>USD</th>
                        <th className={classes.tableCell}>CAD</th>
                        <th className={classes.tableCell}>EUR</th>
                        <th className={classes.tableCell}>GBP</th>
                        <th className={classes.tableCell}>ZAR</th>
                      </tr>
                      <tr>
                        <td className={classes.tableCellValue}>{numeral(data.exchange_usd).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(data.exchange_cad).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(data.exchange_eur).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(data.exchange_gbp).format("0.00")}</td>
                        <td className={classes.tableCellValue}>{numeral(data.exchange_zar).format("0.00")}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          {user.estimatedcost_edit && (
            <>
              <div style={{ paddingTop: "5px", marginRight: "9px", display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" color="primary" style={{ margin: "2px", width: "100px" }} onClick={switchEditing} disabled={loading}>
                  edit
                </Button>
              </div>
              <p style={{ color: "red" }}>{isDuplicate ? "A record with this data has been copied. Please make the necessary changes." : ""}</p>
            </>
          )}
        </div>
      )}
      {editing && (
        <Confirmation
          isOpen={true}
          handleClose={switchEditing}
          handleConfirm={switchEditing}
          title={"Estimated Costs Header"}
          body={undefined}
          handleBackdropClick={handleBackdropClick}
        >
          <EstimatedCostsSetupHeadEditUnstyled
            classes={classes}
            targets={targets}
            currentHead={data}
            submitData={handleSave}
            commodities={commodities}
            market={"market"}
            allData={allData}
            closeDialog={closeDialog}
            setCloseDialog={setCloseDialog}
            handleSave={handleSave}
            packspec_id={packspec_id}
            portsLoading={portsLoading}
          />
        </Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(EstimatedCostsSetupHeadUnstyled);

type EstimatedCostsSetupHeadEditProps = {
  currentHead: any;
  submitData: any;
  commodities: any;
  market: any;
  targets: any;
  allData: any;
  classes: any;
  closeDialog: boolean;
  packspec_id: number;
  portsLoading: { value: number; display: string; data: Port }[];
  setCloseDialog(value: boolean): void;
  handleSave(value: any): void;
};

const EstimatedCostsSetupHeadEditUnstyled: React.FunctionComponent<EstimatedCostsSetupHeadEditProps> = ({
  currentHead,
  submitData,
  commodities,
  allData,
  classes,
  closeDialog,
  setCloseDialog,
  handleSave,
  packspec_id,
  portsLoading,
  targets,
}) => {
  const [loading, setLoading] = useState(false);

  const ports = useMemo(() => (currentHead.port_id ? currentHead.port_id.split(",").map((item: string) => parseInt(item)) : []), [currentHead.port_id]);
  const markets = useMemo(() => (currentHead.market ? currentHead.market.split(",") : []), [currentHead.market]);
  const countries = useMemo(() => (currentHead.countries ? currentHead.countries.split(",").map((cId) => +cId) : []), [currentHead.countries]);

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={{
        ...currentHead,
        packspec_id: packspec_id,
        market: markets,
        port_id: ports,
        countries,
      }}
      onSubmit={(values) => {
        setLoading(true);
        submitData(values);
        setLoading(false);
      }}
      validate={(values) => {
        let errors = {};
        if (values.commodity == "") {
          errors = { ...errors, commodity: "Please select a Commodity" };
        }
        if (values.region == "" || !values.region) {
          errors = { ...errors, region: "Please select a Region" };
        }
        if ((values.market || []).length === 0) {
          errors = { ...errors, market: "Please select a Market" };
        }
        if ((values.countries || []).length === 0) {
          errors = { ...errors, countries: "Please select a Country" };
        }
        if ((values.port_id || []).length === 0) {
          errors = { ...errors, port_id: "Please select a Port" };
        }
        if (values.exchange_usd <= 0) {
          errors = { ...errors, exchange_usd: "The value must be greater than 0" };
        }
        if (values.exchange_cad <= 0) {
          errors = { ...errors, exchange_cad: "The value must be greater than 0" };
        }
        if (values.exchange_eur <= 0) {
          errors = { ...errors, exchange_eur: "The value must be greater than 0" };
        }
        if (values.exchange_gbp <= 0) {
          errors = { ...errors, exchange_gbp: "The value must be greater than 0" };
        }
        if (values.exchange_zar <= 0) {
          errors = { ...errors, exchange_zar: "The value must be greater than 0" };
        }
        return errors;
      }}
      render={({ values, handleSubmit, form, dirty }: any) => (
        <HeadForm
          classes={classes}
          values={values}
          targets={targets}
          commodities={commodities}
          handleSubmit={handleSubmit}
          allData={allData}
          loading={loading}
          form={form}
          dirty={dirty}
          closeDialog={closeDialog}
          setCloseDialog={setCloseDialog}
          handleSave={handleSave}
          portsLoading={portsLoading}
        />
      )}
    />
  );
};

function generateFilteredData(targets: any[], valueKey: string, displayKey: string = valueKey) {
  return targets.reduce((arr, target) => {
    // Check if the key value already exists in the array
    if (!arr.find((item) => item.value === target[valueKey])) {
      arr.push({ value: target[valueKey], display: target[displayKey], target });
    }
    return arr;
  }, []);
}

type HeadFormProps = {
  targets: any;
  values: any;
  commodities: any;
  allData: any[];
  classes: any;
  loading: boolean;
  form: FormApi;
  dirty: boolean;
  closeDialog: boolean;
  portsLoading: { value: number; display: string; data: Port }[];
  handleSubmit(data: any): void;
  setCloseDialog(value: boolean): void;
  handleSave(value: any): void;
};

const HeadForm: React.FC<HeadFormProps> = ({ targets, handleSubmit, values, commodities, allData, classes, loading, form, dirty, closeDialog, handleSave, portsLoading }) => {
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [submittable, setSubmittable] = useState(false);

  useEffect(() => {
    // if ALL countries are selected, then select all countries for duplicate check, otherwise use the selected country
    const spreadValues = { ...values, countries: values.countries.includes(-1) ? targetCountries.map((row) => row.value) : values.countries };
    const doesExists = duplicateCheck(spreadValues, allData, values.packspec_id);
    setIsDuplicate(!!doesExists);
  }, [values, allData]);

  const handleChange = (value: string, name: string) => value;

  useEffect(() => {
    if (!values.container && !values.conventional) {
      setSubmittable(false);
    } else {
      setSubmittable(true);
    }
  }, [values]);

  const handleClose = () => {
    handleSave(undefined);
  };

  const handleConfirm = () => {
    if (values.countries.includes(-1)) {
      values.countries = targetCountries.map((tc) => tc.value);
    }
    handleSubmit(values);
  };

  const openDialog = useMemo(() => {
    if (closeDialog && dirty && !isDuplicate) {
      return true;
    } else if (closeDialog && !dirty) {
      handleClose();
      return false;
    }
    return false;
  }, [dirty, closeDialog]);

  const targetRegions = useMemo(() => generateFilteredData(targets, "region"), [targets]);

  const targetMarkets = useMemo(
    () =>
      generateFilteredData(
        targets.filter((t) => t.region === values.region),
        "market",
      ),
    [targets, values.region],
  );

  const targetCountries = useMemo(
    () =>
      generateFilteredData(
        targets.filter((t) => t.region === (values.region || t.region) && (values.market || []).includes(t.market)),
        "id",
        "country",
      ),
    [targets, values.region, values.market],
  );

  useEffect(() => {
    // if 'Market' is changed, only keep the selected countries that are available in the Target Countries list
    if (form.getState().dirtyFields.market) {
      const reduced = values.countries.filter((vCount) => targetCountries.find((tCount) => tCount.value === vCount));
      form.change("countries", reduced);
    }
  }, [form.getState().dirtyFields.market, values.market]);

  useEffect(() => {
    const targetCountriesLength = (targetCountries || []).length;
    const selectedCountriesLength = (values.countries || []).length;

    // if the Length of available Target Countries is the same as the selected Countries, then "ALL" is selected
    if (targetCountriesLength > 0 && selectedCountriesLength > 0 && targetCountriesLength === selectedCountriesLength) {
      form.change("countries", [-1]);
    }
  }, []);

  return (
    <form style={{ maxWidth: "450px" }} onSubmit={handleSubmit}>
      <div className={classes.formContainer}>
        <FieldText classes={classes} field="season" title="Season" />
        <FieldDropdown
          fullWidth
          form={form}
          field="commodity"
          title="Commodity"
          classes={classes}
          data={(commodities || []).map((commItem) => ({ value: commItem.value, display: commItem.display }))}
        />
        <FieldDropdown classes={classes} form={form} fullWidth hideNone title="Region" field={"region"} data={targetRegions} />
        <FieldDropdown
          hideNone
          multiple
          fullWidth
          form={form}
          title="Market"
          field={"market"}
          classes={classes}
          data={targetMarkets}
          disabled={!values.region || values.region == ""}
        />
        <FieldDropdown
          hideNone
          multiple
          fullWidth
          includeAll
          form={form}
          title="Country"
          classes={classes}
          field={"countries"}
          data={targetCountries}
          disabled={values.region == "" || (values.market || []).length === 0}
        />
        <FieldDropdown classes={classes} form={form} fullWidth multiple title="Port of Loading" field={"port_id"} data={portsLoading} />
        <FieldCheckbox classes={classes} field="container" title="Container" handleChange={handleChange} />
        <FieldCheckbox classes={classes} field="conventional" title="Conventional" handleChange={handleChange} />
        <div className={classes.exchange}>
          <FormHelperText>Exchange:</FormHelperText>
          <div className={classes.exchangeWrapper}>
            <ExchangeFieldText classes={classes} field="exchange_usd" title="USD" />
            <ExchangeFieldText classes={classes} field="exchange_cad" title="CAD" />
            <ExchangeFieldText classes={classes} field="exchange_eur" title="EUR" />
            <ExchangeFieldText classes={classes} field="exchange_gbp" title="GBP" />
            <ExchangeFieldText classes={classes} field="exchange_zar" title="ZAR" />
          </div>
        </div>
      </div>
      <p style={{ color: "red" }}>{!submittable && "Either CONVETIONAL or CONTAINER must be selected."}</p>
      <p style={{ color: "red" }}>{isDuplicate && "Please make the necessary changes"}</p>
      <div className={classes.actionFormButton}>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={handleConfirm}
          style={{ margin: "2px", width: "100px" }}
          disabled={isDuplicate || loading || !submittable}
        >
          save
        </Button>
      </div>
      <Confirmation isOpen={openDialog} handleClose={handleClose} handleConfirm={handleConfirm} body="Do you want to save your changes?" title="Unsaved changes" />
    </form>
  );
};

type FieldDropdownProps = {
  classes: any;
  fullWidth?: boolean;
  multiple?: boolean;
  field: string;
  title: string;
  data: any[];
  form: FormApi;
  includeAll?: boolean;
  hideNone?: boolean;
  disabled?: boolean;
};

const FieldDropdown: FC<FieldDropdownProps> = ({ classes, fullWidth, multiple, title, field, data, form, includeAll, disabled, hideNone = false }) => {
  const handleParse = (value: any | any[]) => {
    if (field === "region") {
      form.change("market", []);
      form.change("countries", []);
    }
    if (field === "countries") {
      // if the last value selected is "ALL", remove all other selections
      if (value[value.length - 1] === -1) {
        return [-1];
      }
      // remove "ALL" from selection
      return value.filter((v: any) => v != -1);
    }
    return value;
  };

  return (
    <div className={classes.field}>
      <FormHelperText>{title}:</FormHelperText>
      <Field
        name={field}
        childdata={data}
        disabled={disabled}
        parse={handleParse}
        hideNone={hideNone}
        multiple={multiple}
        fullWidth={fullWidth}
        includeAll={includeAll}
        component={MaterialSelectComponent}
      />
    </div>
  );
};

type FieldTextProps = {
  classes: any;
  field: string;
  title: string;
};

const FieldText: FC<FieldTextProps> = ({ classes, title, field }) => (
  <div className={classes.field}>
    <FormHelperText>{title}:</FormHelperText>
    <Field fullWidth name={field} component={TextField} type="text" margin="dense" />
  </div>
);

const ExchangeFieldText: FC<FieldTextProps> = ({ classes, title, field }) => (
  <div className={classes.exchangeField}>
    <FormHelperText>{title}:</FormHelperText>
    <Field name={field} component={TextField} type="text" margin="dense" />
  </div>
);

type FieldCheckboxProps = {
  classes: any;
  field: string;
  title: string;
  handleChange(value: string, name: string): void;
};

const FieldCheckbox: FC<FieldCheckboxProps> = ({ classes, title, field, handleChange }) => (
  <div className={classes.field}>
    <FormHelperText>{title}:</FormHelperText>
    <div style={{ width: "100%" }}>
      <Field fullWidth name={field} component={Checkbox} type="checkbox" margin="dense" parse={handleChange} />
    </div>
  </div>
);
